








import { Component, Vue, Prop } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'

@Component
export default class NextPrev extends Vue {
  @Prop({ required: false, default: null })
  private to!: RawLocation | null

  @Prop({ required: true, default: 'left' })
  private direction?: 'left' | 'right'

  private goToRoute(): void {
    if (this.to) {
      this.$router.push(this.to)
    }
  }
}
