






























































































































































































































































import {RawLocation, Route} from 'vue-router'
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'
import {projectModule, stateModule} from '@/store'
import {proposalSortOptions} from '@/data/proposals'
import {DEFAULT_PROPOSAL_FILTERS} from '@/helpers/proposals'
import { mixin as clickaway }   from '@/plugins/vue-clickaway.ts'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import Tabs from '@/components/widgets/Tabs.vue'
import Modal from '@/components/widgets/Modal.vue'
import Submit from '@/components/project/Submit.vue'
import NoItems from '@/components/partials/NoItems.vue'
import QuickList from '@/components/list/QuickList.vue'
import NextPrev from '@/components/widgets/NextPrev.vue'
import SearchInput from '@/components/inputs/Search.vue'
import ActionBar from '@/components/widgets/ActionBar.vue'
import DropdownInput from '@/components/inputs/Dropdown.vue'
import DropdownSort from '@/components/inputs/DropdownSort.vue'
import DetailsHeader from '@/components/header/DetailsHeader.vue'
import ResponseModal from '@/components/widgets/ResponseModal.vue'
import ProposalsList from '@/components/project/ProposalsList.vue'
import ProposalSelect from  '@/views/dashboard/projects/detail/process/Select.vue'
import DateInput from '@/components/inputs/Date.vue'

import { ProcessResource } from '@/models/process/ProcessResource'
import { ProjectResource } from '@/models/projects/ProjectResource'
import {ProposalIndexRequest} from '@/requests/proposals/ProposalIndexRequest'
import {StatusResourceCollection} from '@/models/status/StatusResourceCollection'
import {ProposalCollectionResource} from '@/models/proposals/ProposalCollectionResource'
import {ProposalCollectionLightResource} from '@/models/proposals/ProposalCollectionLightResource'

// Clone route because there is a bug when using Route inside a Watcher the decorator throws an error
type ClonedRoute = Route

@Component({
  components: {
    Tabs,
    Modal,
    Submit,
    NoItems,
    NextPrev,
    DateInput,
    ActionBar,
    QuickList,
    SearchInput,
    DropdownSort,
    ResponseModal,
    DetailsHeader,
    ProposalsList,
    DropdownInput,
    ProposalSelect
  },
  mixins: [clickaway],
})
export default class ProjectProcess extends Vue {

  @Prop()
  private readonly project!: ProjectResource

  private showCreateModal: boolean = false
  private editing: boolean = false
  private showFilters: boolean = false

  private quickListOpen: boolean = true

  private sortOptions: SelectItem[] = proposalSortOptions

  private proposals: ProposalCollectionResource[] = []
  private pagination: Pagination | null = null

  private proposalFilters: { [key: string]: { [key: string]: string } } = {}
  private proposalParams: ProposalIndexRequest  = { ...DEFAULT_PROPOSAL_FILTERS }
  private defaultFilters: ProposalIndexRequest  = { ...DEFAULT_PROPOSAL_FILTERS }

  private selectedProposals: ProposalCollectionLightResource[] = []

  private get selectedProposalIds(): number[] {
    return this.selectedProposals.map(({id}) => id)
  }

  private get process(): ProcessResource | undefined {
    return this.project.processes.find((process) => process.id === parseInt(this.$route.params.process_id, 10))
  }

  private get nextProcess(): ProcessResource | undefined {
    return this.process ? this.project.processes.find((process) => process.order === (this.process ? this.process.order + 1 : -1)) : undefined
  }

  private get previousProcess(): ProcessResource | undefined {
    return this.process ? this.project.processes.find((process) => process.order === (this.process ? this.process.order - 1 : -1)) : undefined
  }

  private get previousProcessName(): string {
    return this.previousProcess?.name ?? ''
  }

  private get nextProcessName(): string {
    return this.nextProcess?.process_name ?? ''
  }

  private get processNeedsManualCreatedProposals(): boolean {
    return this.process?.process_type === 'document' || this.process?.process_type === 'default'
  }

  private get proposalCreationViaDocument(): boolean {
    return this.process?.process_type === 'document'
  }

  private get proposalCreationViaTagging(): boolean {
    return this.process?.process_type === 'marking'
  }

  private get showSideProposal(): boolean {
    return this.$route.name === 'projects-detail-proposal-detail'
  }

  private get showSideMultiSelect(): boolean {
    return this.selectedProposalIds.length > 0
  }

  private get showSide(): boolean {
    return this.showSideProposal || this.showSideMultiSelect
  }

  private get filtersActive(): boolean {
    return !isEqual({ ...this.proposalParams }, this.defaultFilters)
  }

  private get canSelect(): boolean {
    return !this.editing
  }

  private get canCreate(): boolean {
    // If first process we can only create something from within the document.
    return this.process
        ? this.process.canPerformAction('can_create_proposal')
        : false
  }

  private get isProcessPage(): boolean {
    return this.$route.name === 'projects-detail-process'
  }

  private get canAddDocument(): boolean {
    return this.project.canPerformAction('can_add_documents')
  }

  private get openProposalId(): number | null {
    return this.$route.params.proposal_id
        ? parseInt(this.$route.params.proposal_id, 10)
        : null
  }

  private get allItemsSelected(): boolean {
    return this.proposals.every(({id}) => this.isSelected(id))
  }

  private get selectComponent(): string {
    return this.allItemsSelected ? 'selectedBoxIcon' : 'selectBoxIcon'
  }

  private get currentStatus(): string | null {
    return (this.$route.query.status as string) || null
  }

  private get itemCount(): number {
    return this.proposals.length
  }

  private get statusFilters(): Array<Partial<StatusResourceCollection>> {
    let filters: Array<Partial<StatusResourceCollection>> = []

    if (this.process && typeof this.process.statuses === 'object') {
      filters = [...this.process.statuses]
    }

    return filters
  }

  private get parentFilters(): SelectItem[] {
    if (this?.proposalFilters?.parents && this.previousProcess) {
      const FILTERS = [
        {
          value: 'NA',
          label: `No ${this.previousProcessName} connected`,
        },
      ]

      Object.keys(this.proposalFilters.parents).map((key: string) => {
        FILTERS.push({
          value: key,
          label: this.proposalFilters.parents[key],
        })
      })

      return FILTERS
    }

    return []
  }

  private get childFilters(): SelectItem[] {
    if (this.proposalFilters?.children && this.nextProcess) {
      const FILTERS = [
        {
          value: 'NA',
          label: `No ${this.nextProcessName} connected`,
        },
      ]

      Object.keys(this.proposalFilters.children).map((key: string) => {
        FILTERS.push({
          value: key,
          label: this.proposalFilters.children[key],
        })
      })

      return FILTERS
    }

    return []
  }

  private get allCurrentProposalsSelected(): boolean {
    return this.proposals.every((proposal) => this.isSelected(proposal.id))
  }

  private get detailBackRoute(): RawLocation {
    const params = {...this.$route.params}
    delete params.proposal_id
    return {name: 'projects-detail-process', params}
  }

  @Watch('process.id')
  private async onProcessChange(processId: number, oldProcessId: number): Promise<void> {
    if (processId !== oldProcessId) {
      this.proposalParams = { ...DEFAULT_PROPOSAL_FILTERS, component_values: {} }
      await this.getData()
      this.resetSelectedItems()
      this.setInitialFilters()
    }
  }

  private goToOverview() {
    this.$router.push(this.detailBackRoute)
  }

  private async mounted() {
    // get init statuses
    this.proposalParams.status = this.$route.query.status as string[] || []
    this.proposalParams.page = this.$route.query.page || '1'
    this.proposalParams.coverage =
    this.$route.query.coverage || undefined

    // Get initial data
    await this.getData()
    this.setInitialFilters()

    // Open create modal when query new is inside rotue
    if (this.$route.query.new) {
      this.showCreateModal = true
    }
    if (this.$route.params.proposal_id) {
      this.$nextTick(() => {
        document
            .getElementById(this.$route.params.proposal_id)
            ?.scrollIntoView({
              block: 'center',
              inline: 'nearest',
              behavior: 'smooth',
            })
      })
    }
  }

  @Watch('$route')
  private async onRouteChange(val: ClonedRoute, from: ClonedRoute): Promise<void> {
    // Scroll element into view
    if (val.name === 'projects-detail-proposal-detail') {
      this.deselectAll()
      this.$nextTick(() => {
        document.getElementById(val.params.proposal_id)?.scrollIntoView({
          block: 'start',
          inline: 'nearest',
          behavior: 'smooth'
        })
      })
    }
  }

  private isSelected(id: number): boolean {
    return this.selectedProposals.some((proposal) => proposal.id === id)
  }

  private showNavigationButton(process: boolean): boolean {
    return process && !this.showSide && !this.quickListOpen
  }

  // These are only here to check if the process exists otherwise we will redirect away
  private async beforeRouteEnter(to: Route, from: Route, next: any): Promise<void> {
    const process = projectModule.getProcessById(parseInt(to.params.process_id, 10))
    if (process) {
      next()
    } else {
      next(from.path || { name: 'dashboard' })
    }
  }

  private async beforeRouteUpdate(to: Route, from: Route, next: any): Promise<void> {
    if (parseInt(to.params.process_id, 10) === parseInt(from.params.process_id, 10)) {
      next()
    } else {
      if (this.process) {
        next()
      } else {
        next(from.path || { name: 'dashboard' })
      }
    }
  }

  private toggleSelect(): void {
    const CURRENT_PROPOSAL_IDS = this.proposals.map(({id}) => id)
    // All proposals are already selected, So we deselect all CURRENT proposals
    if (this.allCurrentProposalsSelected) {
      this.selectedProposals = this.selectedProposals.filter((proposal) => !CURRENT_PROPOSAL_IDS.includes(proposal.id))
      // Select all current proposals
    } else {
      const REMAINING_PROPOSALS = this.proposals.filter(({id}) => !this.isSelected(id))
      this.selectedProposals.push(...REMAINING_PROPOSALS.map((p) => new ProposalCollectionLightResource(p)))
    }
  }

  // Toggle select proposal
  private updateSelected(proposal: ProposalCollectionResource): void {
    const PROPOSAL_INDEX = this.selectedProposals.findIndex((p) => p.id === proposal.id)

    // Remove proposal
    if (PROPOSAL_INDEX > -1) {
      this.selectedProposals.splice(PROPOSAL_INDEX, 1)
      return
    }

    // Add proposal
    this.selectedProposals.push(new ProposalCollectionLightResource(proposal))

    if (this.showSideProposal && this.selectedProposals.length > 0) {
      this.$router.push(this.detailBackRoute)
    }
  }

  private deselectProposal(proposalId: number) {
    const PROPOSAL_INDEX = this.selectedProposals.findIndex((p) => p.id === proposalId)
    if (PROPOSAL_INDEX === -1) return
    this.selectedProposals.splice(PROPOSAL_INDEX, 1)
  }

  private deselectAll() {
    this.selectedProposals = []
  }

  private setProposalParam(key: string, value: string): void {
    this.proposalParams[key] = value
  }

  private setSortParam(sort: string): void {
    const [column, dir] = sort.split('-')

    this.setProposalParam('column', column)
    this.setProposalParam('dir', dir)

    this.applyFilters()
  }

  private applyFilters(): void {
    this.setProposalParam('page', '1')

    this.$router.push({
      name: 'projects-detail-process',
      query: this.proposalParams,
    })
    this.getData()
    if (this.showFilters) {
      this.toggleFilters()
    }
  }

  private async clearFilters(): Promise<void> {
    this.proposalParams = { ...DEFAULT_PROPOSAL_FILTERS, component_values: {} }

    this.$router.push({ name: 'projects-detail-process', query: {} })
    if (this.showFilters) {
      this.toggleFilters()
    }
    await this.getData()
    this.setInitialFilters()
  }

  private toggleFilters(): void {
    this.showFilters = !this.showFilters
  }

  private closeFilters(): void {
    this.showFilters = false
  }

  private async filterChangedHandler(key?: string): Promise<void> {
    this.proposalParams.status = key
    this.$router.push({
      name: 'projects-detail-process',
      query: this.proposalParams,
    })
    this.getData()
  }

  private proposalCreated(): void {
    this.getData()
    this.process?.refresh()
  }

  private setInitialFilters() {
    for (const { id } of this.process?.componentFilters ?? []) {
      if (this.proposalParams.component_values) {
        Vue.set(this.proposalParams.component_values, id, [])
      }
    }
    // @ts-ignore
    for (const { id } of this.proposalFilters?.components ?? []) {
      if (this.proposalParams.component_values) {
        Vue.set(this.proposalParams.component_values, id, [])
      }
    }

    this.defaultFilters = cloneDeep(this.proposalParams)
  }

  private async getData(): Promise<void> {
    if (this.process) {
      try {
        stateModule.setLoading(true)
        const proposals = await this.process.getProposals(this.proposalParams)
        this.$set(this, 'proposals', proposals.data)
        this.$set(this, 'pagination', proposals.pagination)
        this.$set(this, 'proposalFilters', proposals.filters)
      } finally {
        stateModule.setLoading(false)
      }
    }
  }

  private resetSelectedItems(): void {
    this.selectedProposals = []
  }

  private openProposal(data: ProposalCollectionResource): void {
    if (`${data.id}` !== this.$route.params.proposal_id) {
      this.$router.push({
        name: 'projects-detail-proposal-detail',
        params: {
          ...this.$route.params,
          process_id: `${data.process_id}`,
          proposal_id: `${data.id}`,
        },
      })
    }
  }

  private onEditing(val: boolean): void {
    this.editing = val
  }

  private async goToPage(page: string): Promise<void> {
    this.proposalParams.page = page
    await this.getData()
    this.scrollTop()
  }

  private scrollTop(): void {
    const proposalContainer = this.$refs.proposalList as Vue
    if (!proposalContainer) return
    proposalContainer.$el.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }

  private getProposalsIndex(): void {
    if (this.process) {
      this.process.getProposalsIndex()
    }
  }

  private toggleQuickList(): void {
    this.quickListOpen = !this.quickListOpen
    this.getProposalsIndex()
  }

}
